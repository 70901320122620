import React from 'react';
import styled from 'styled-components';

import BkgImage from '../../images/gabriel.jpg';
import logo from '../../images/logo.png';

const Container = styled.div`
  background: linear-gradient(
      to right,
      rgba(1, 1, 1, 0.3) 20%,
      rgba(1, 1, 1, 1) 150%
    ),
    url(${BkgImage});
  background-size: cover;

  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

const TextContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  display: block;
  text-transform: uppercase;
  color: white;
  letter-spacing: 3px;
  font-size: 1.8rem;
  font-weight: 300;
  ${'' /* text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.3);  */};
`;

const LinkContainer = styled.div`
  text-align: center;
  margin-top: 1.8rem;

  a {
    text-decoration: none;
    color: white;
    font-weight: 300;
    line-height: 1.8rem;
    letter-spacing: 1.4px;
  }

  a:hover {
    color: #ff6f69;
  }
`;

const Logo = styled.img`
  width: 220px;
`;

const Links = () => {
  return (
    <LinkContainer>
      {LINKS.map(link => (
        <a href={link.link} target="_blank">
          <p key={link.id}>{link.name}</p>
        </a>
      ))}
    </LinkContainer>
  );
};

const LINKS = [
  {
    name: 'SoundCloud',
    link: 'http://soundcloud.com/gabriel_baltazar',
  },
  {
    name: 'Facebook',
    link: 'http://www.facebook.com/gabrielbaltazarmusic/',
  },
  {
    name: 'Instagram',
    link: 'http://www.instagram.com/gabrielbaltazarmusic/',
  },
  {
    name: 'Twitter',
    link: 'http://twitter.com/gbaltazarmusic',
  },
  {
    name: 'Resident Advisor',
    link: 'http://www.residentadvisor.net/dj/gabrielbaltazar',
  },
  {
    name: 'Email',
    link: 'mailto:hello@gabrielbaltazar.com',
  },
];

const Hero = () => (
  <Container>
    <TextContainer>
      <Logo src={logo} alt="logo" />

      <Links />
    </TextContainer>
  </Container>
);

export default Hero;
